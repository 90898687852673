import { CountriesModule } from './modules/admin/countries/countries/countries.module';
import { CompaniesModule } from './modules/admin/companies/companies.module';
import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },

    // Auth routes for guests
    {
        path: '',
        //canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            //{path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            //{path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
        ]
    },

    // Landing routes
    // {
    //     path: '',
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children: [
    //         { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
    //     ]
    // },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        // resolve: {
        //     initialData: InitialDataResolver,
        // },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/admin/home/home.module').then(m => m.HomeModule) },
            { path: 'downloads', loadChildren: () => import('app/modules/admin/downloads/downloads.module').then(m => m.DownloadsModule) },
            { path: 'potential-companies', loadChildren: () => import('app/modules/admin/potential-companies/potential-companies.module').then(m => m.PotentialCompaniesModule) },
            { path: 'companies', loadChildren: () => import('app/modules/admin/companies/companies.module').then(m => m.CompaniesModule) },
            { path: 'users', loadChildren: () => import('app/modules/admin/users/users.module').then(m => m.UsersModule) },
            { path: 'lines', loadChildren: () => import('app/modules/admin/lines/lines/lines.module').then(m => m.LinesModule) },
            { path: 'glasses', loadChildren: () => import('app/modules/admin/glasses/glasses.module').then(m => m.GlassesModule) },
            { path: 'glass-decorations', loadChildren: () => import('app/modules/admin/glasses/glass-decorations/glass-decorations.module').then(m => m.GlassDecorationModule)},
            { path: 'products', loadChildren: () => import('app/modules/admin/products/products.module').then(m => m.ProductsModule) },
            { path: 'sellers', loadChildren: () => import('app/modules/admin/sellers/sellers.module').then(m => m.SellersModule) },
            { path: 'clients', loadChildren: () => import('app/modules/admin/clients/clients.module').then(m => m.ClientsModule) },
            { path: 'projects', loadChildren: () => import('app/modules/admin/projects/projects.module').then(m => m.ProjectsModule) },
            { path: 'settings', loadChildren: () => import('app/modules/admin/settings/settings.module').then(m => m.SettingsModule) },
            { path: 'profiles', loadChildren: () => import('app/modules/admin/profiles/profiles.module').then(m => m.ProfilesModule) },
            { path: 'budget-default-items', loadChildren: () => import('app/modules/admin/budget-default-items/budget-default-items.module').then(m => m.BudgetDefaultItemsModule) },
            { path: 'countries', loadChildren: () => import('app/modules/admin/countries/countries/countries.module').then(m => m.CountriesModule) },
            { path: 'statistics', loadChildren: () => import('app/modules/admin/statistics/statistics.module').then(m => m.StatisticsModule) },
            


        ]
    },

    {
        path: '',
        children: [
            { path: '404', loadChildren: () => import('app/modules/error/error-404/error-404.module').then(m => m.Error404Module) },
        ]
    },

    // Redirect unmatched paths
    { path: '**', redirectTo: '404' },
];
