import { MatPaginatorIntl } from '@angular/material/paginator';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthModule } from 'app/core/auth/auth.module';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { CustomPaginator } from 'app/shared/config/ccustom-paginator';
import { TranslocoService } from '@ngneat/transloco';

import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import { registerLocaleData } from '@angular/common';
import {MAT_DATE_LOCALE} from '@angular/material/core';

import localeCl from '@angular/common/locales/es-AR';
import localeClExtra from '@angular/common/locales/extra/es-AR';

registerLocaleData(localeCl, 'es-CL', localeClExtra);

// import localeEn from '@angular/common/locales/es';
// registerLocaleData(localeEn, 'es', localeEsExtra);
@NgModule({
    imports: [
        AuthModule,
        IconsModule,
        TranslocoCoreModule
    ],
    providers: [
        {
            provide: MatPaginatorIntl,
            useFactory: (translate) => {
                const service = new CustomPaginator();
                service.injectTranslateService(translate);
                return service;
            },
            deps: [TranslocoService]
        },
        {provide: MAT_DATE_LOCALE, useValue: 'es'},
        {provide: LOCALE_ID, useValue: 'es-CL'}
    ]
})
export class CoreModule {
    /**
     * Constructor
     */
    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
