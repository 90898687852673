import { TranslatableFieldModel } from 'app/shared/models/data/translatable-field.model';
import { ColorNameComponent } from '../../../../../shared/components/color-name/color-name.component';
import { Serializable } from "app/core/entities/serializable.model";

export class CountryTaxModel extends Serializable {
    public id: number;
    public name: TranslatableFieldModel;
    public nameLang: string;
    public shortName: string;
    public average: number;
    public countryId: number;
    public enabled: boolean;

    newModel(data: any): CountryTaxModel {
        return new CountryTaxModel(data);
    }

    // solo me quedo con las variables que necesito para el filtro
    prepareDataToFilter(): any {
        return {
            id: this.id,
            shortName: this.shortName,
            nameLang: this.nameLang,
            average: this.average,
            enabled: this.enabled
        }
    }

    prepareForStore(): any {
        const data = {
            short_name: this.shortName,
            name: this.name,
            average: this.average,
            country_id: this.countryId,
        };
        return data;
    }
}