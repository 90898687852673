import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule()
export class IconsModule
{
    /**
     * Constructor
     */
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry
    )
    {
        // Register icon sets
        this._matIconRegistry.addSvgIconSet(this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('mat_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('mat_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('feather', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg'));
        this._matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg'));

        this._matIconRegistry.addSvgIcon('es-flag', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/flags/es.svg'));
        this._matIconRegistry.addSvgIcon('en-flag', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/flags/en.svg'));

        this._matIconRegistry.addSvgIcon('custom_connector', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom/connector.svg'));
        this._matIconRegistry.addSvgIcon('custom_divider_horizontal', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom/divider_horizontal.svg'));
        this._matIconRegistry.addSvgIcon('custom_divider_vertical', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom/divider_vertical.svg'));
        this._matIconRegistry.addSvgIcon('custom_glass', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom/glass.svg'));
        this._matIconRegistry.addSvgIcon('custom_profile_bar_simple', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom/profile_bar_simple.svg'));
        this._matIconRegistry.addSvgIcon('custom_profile_bar_double', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom/profile_bar_double.svg'));
        this._matIconRegistry.addSvgIcon('custom_window', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom/window.svg'));
        this._matIconRegistry.addSvgIcon('custom_sash', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom/sash.svg'));
        this._matIconRegistry.addSvgIcon('custom_micropersiana', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom/micropersiana.svg'));
        this._matIconRegistry.addSvgIcon('custom_palillos', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom/palillos.svg'));
        this._matIconRegistry.addSvgIcon('custom_sash_fixed', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom/sash_fixed.svg'));
        this._matIconRegistry.addSvgIcon('custom_sash_movable', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom/sash_movable.svg'));
        this._matIconRegistry.addSvgIcon('custom_open_direction', this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom/open_direction.svg'));
        
    }
}
