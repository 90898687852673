import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { GeneralResponse } from '../../shared/models/general-response.model';
import { FilterModel } from '../../shared/models/filter.model';
import { throwError } from 'rxjs';


@Injectable()
export class BaseService {

    public _api = environment.api;
    public _headers = new HttpHeaders();
    protected _filters: FilterModel[] = [];
    protected _sorts = '';

    constructor(public _router: Router, _filters: FilterModel[] = []) {
        this._headers.set('Accept', 'application/json')
            .append('Access-Control-Allow-Origin', '*')
            .append('Content-type', 'application/x-www-form-urlencoded')
            .append('X-Requested-With', 'XMLHttpRequest');

        this._filters = _filters;
        this._sorts = '';
    }

    filterFormat(field: string, value: any): string {
        return `&filter[${field}]=${value}&`;
    }

    includesFormat(includes: any[]): string {
        return includes.length
            ? includes.reduce((accum, item) => `${accum}${item},`, '&include=')
            : '';
    }

    public handleError(error: HttpErrorResponse | any): any {
        const response = new GeneralResponse();
        response.data = [];
        response.error = true;

        if (error.error?.data) {
            response.message = error.error.data.message;

            if (error.error.data.httpCode === 401) {
                this._router.navigate(['/sign-out']);
            }

            if (error.error.data.errors) {
                const arrayErrors = Object.keys(error.error.data.errors).map(i => error.error.data.errors[i]);
                for (const err of arrayErrors) {
                    response.data.push(err);
                }
            }
        }

        if (error.error) {
            response.message = error.error?.message;

            if (error.status === 401) {
                this._router.navigate(['/sign-out']);
            }

            // if (error.status === 403) {
            //     this._router.navigate(['/403']);
            // }

            if (error.error?.errors) {

                const arrayErrors = Object.keys(error.error?.errors).map(i => error.error?.errors[i]);
                for (const err of arrayErrors) {
                    response.data.push(err);
                }
            }
        }

        if (response.message === '') {
            response.message = 'Ocurrio un problema con la comunicación con el servidor. Verfique su conexion y vuelva a intentarlo.';
        }

        return throwError(() => response);
    }

    // ***** filters
    get filters(): FilterModel[] {
        return this._filters;
    }

    set filters(filters: FilterModel[]) {
        this._filters = filters;
    }

    get sorts(): string {
        return this._sorts;
    }

    set sorts(sorts) {
        this._sorts = sorts;
    }

    cleanFilters(): void {
        for (const filter of this._filters) {
            if (filter.isArray) {
                filter.value = [];
            } else {
                filter.value = '';
            }
        }
    }
}
