import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { FuseNavigationItem } from '@fuse/components/navigation';
// import { Navigation } from 'app/core/navigation/navigation.types';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    //private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    // get navigation$(): Observable<Navigation>
    // {
    //     return this._navigation.asObservable();
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    // get(): Observable<Navigation>
    // {
    //     return this._httpClient.get<Navigation>('api/common/navigation').pipe(
    //         tap((navigation) => {
    //             this._navigation.next(navigation);
    //         })
    //     );
    // }

    // funcion recursiva para parsear el archivo sidebarNavigation en base a los permisos del usuario
    // y traduciendo los titulos de los items, faltan inplementar algunos fuse navigation types
    createNavigation(items: any[], translates: any): FuseNavigationItem[] {
        const navItems = [];

        items.forEach(item => {
            let title = translates[item.title];

            if (item.type === 'basic') {
                navItems.push({
                    ...item,
                    title
                });
            }
            if (item.type === 'collapsable') {
                const children = this.createNavigation(item.children, translates);

                if (children?.length) {
                    navItems.push({
                        ...item,
                        title,
                        children
                    })
                }
            }
        });

        return navItems;
    }
}
