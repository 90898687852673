import { Serializable } from '../../core/entities/serializable.model';
import { FactoryModel } from './data/factory.model';
import { LangModel } from './data/lang.model';
import { UserCompanyProfileModel } from './user/user-company-profile.model';
export class Auth extends Serializable {

    static _ADMIN = 'admin';
    static _COMPANY = 'company';

    public id: string;
    public name: string;
    public email: string;
    public token: string;
    public tokenType: string;
    public type: string;
    public factory: FactoryModel;
    public language: LangModel;
   // public userCompanyProfile: UserCompanyProfileModel;
    public reports: string[];

    newModel(data: any): Auth {
        const dataParsed = new Auth(data);
        dataParsed.language = LangModel.createOne(data.language, new LangModel);
        dataParsed.factory = FactoryModel.createOne(data.factory, new FactoryModel);

        // if (data.userCompanyProfile) {
        //     dataParsed.userCompanyProfile = UserCompanyProfileModel.createOne(data.userCompanyProfile, new UserCompanyProfileModel);
        // }

        return dataParsed;
    }

    get isAdmin() {
        return this.type == Auth._ADMIN;
    }

    get isCompany() {
        return this.type == Auth._COMPANY;
    }
}
