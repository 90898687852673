import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: number, currencyCode: string = 'USD', symbolDisplay: boolean = true): string {
    
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
    });

    // Format the number with dot instead of comma for thousands separator
    const formattedValue = formatter.format(value).replace(/\./g, '|').replace(/,/g, '.').replace(/\|/g, ',');

    return symbolDisplay ? formattedValue : formattedValue.replace(currencyCode, '').trim();
  }
}