export const sidebarCompanyNavigation = [
  {
    id: 'home',
    title: 'NAVIGATION.HOME',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/home'
  },
  {
    id: 'projects',
    title: 'NAVIGATION.PROJECTS',
    type: 'basic',
    icon: 'heroicons_outline:view-grid-add',
    link: '/projects'
  },
  {
    id: 'clients',
    title: 'NAVIGATION.CLIENTS',
    type: 'basic',
    icon: 'heroicons_outline:user-group',
    link: '/clients'
  },
  {
    id: 'sellers',
    title: 'NAVIGATION.SELLERS',
    type: 'basic',
    icon: 'heroicons_outline:identification',
    link: '/sellers'
  },
  {
    id: 'downloads',
    title: 'NAVIGATION.DOWNLOADS',
    type: 'basic',
    icon: 'mat_outline:file_download',
    link: '/downloads'
  },
  {
    id: 'configurations',
    title: 'NAVIGATION.CONFIGURATION',
    type: 'collapsable',
    icon: 'heroicons_outline:cog',
    children: [
      {
        id: 'my-company',
        title: 'NAVIGATION.COMPANY',
        type: 'basic',
        link: '/companies/edit'
      },
      {
        id: 'users',
        title: 'NAVIGATION.USERS',
        type: 'basic',
        link: '/users/list'
      },
      {
        id: 'settings',
        title: 'NAVIGATION.SETTINGS',
        type: 'basic',
        link: '/settings'
      },
      {
        id: 'glasses',
        title: 'NAVIGATION.GLASSES',
        type: 'basic',
        link: '/glasses'
      },
      {
        id: 'budget-default-items',
        title: 'NAVIGATION.BUDGET_DEFAULT_ITEMS',
        type: 'basic',
        link: '/budget-default-items'
      },
      {
        id: 'lines',
        title: 'NAVIGATION.LINES',
        type: 'basic',
        link: '/lines'
      },
    ]
  },
];