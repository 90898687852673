import {Component, Inject, OnInit} from '@angular/core';
import {CustomSnackBarModel} from './custom-snack-bar.model';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snack-bar',
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.scss']
})
export class CustomSnackBarComponent implements OnInit {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackBarModel) {}

    ngOnInit(): void {}

}
