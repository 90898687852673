import { Serializable } from "app/core/entities/serializable.model";

export class CurrencyModel extends Serializable{
    public id: number;
    public tag: string;
    public name: string;
    public symbol: string;

    newModel(data: any): CurrencyModel {
        return new CurrencyModel(data);
    }
}