
// este arreglo se parsea a FuseNavigationItems  en el classic.component
// con la funcion createNavigation, solo están implementados los tipos 'basic' y 'collapsable'
export const sidebarNavigation = [
  {
    id: 'home',
    title: 'NAVIGATION.HOME',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/home'
  },
  {
    id: 'home',
    title: 'NAVIGATION.STATISTICS',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/statistics'
  },
  {
    id: 'products',
    title: 'NAVIGATION.PRODUCTS',
    type: 'collapsable',
    icon: 'heroicons_outline:template',
    children: [
      {
        id: 'lines',
        title: 'NAVIGATION.LINES',
        type: 'basic',
        link: '/lines'
      },
      {
        id: 'products',
        title: 'NAVIGATION.PRODUCTS',
        type: 'basic',
        link: '/products'
      },
      {
        id: 'glasses',
        title: 'NAVIGATION.GLASSES',
        type: 'basic',
        link: '/glasses'
      },
      {
        id: 'micropersianas',
        title: 'NAVIGATION.MICROPERSIANAS',
        type: 'basic',
        link: '/glass-decorations/micropersianas'
      },
      {
        id: 'palillos',
        title: 'NAVIGATION.PALILLOS',
        type: 'basic',
        link: '/glass-decorations/palillos'
      },
    ]
  },
  {
    id: 'companies',
    title: 'NAVIGATION.COMPANIES',
    type: 'collapsable',
    icon: 'heroicons_outline:office-building',
    children: [
      {
        id: 'companies',
        title: 'NAVIGATION.COMPANIES_ACTIVE',
        type: 'basic',
        link: '/companies'
      },
      {
        id: 'potential_companies',
        title: 'NAVIGATION.POTENTIAL_COMPANIAS',
        type: 'basic',
        link: '/potential-companies'
      },
    ]
  },
  {
    id: 'downloads',
    title: 'NAVIGATION.DOWNLOADS',
    type: 'basic',
    icon: 'mat_outline:file_download',
    link: '/downloads'
  },
  {
    id: 'configurations',
    title: 'NAVIGATION.CONFIGURATION',
    type: 'collapsable',
    icon: 'heroicons_outline:cog',
    children: [
      {
        id: 'users',
        title: 'NAVIGATION.ADMIN',
        type: 'basic',
        link: '/users'
      },
      {
        id: 'profiles',
        title: 'NAVIGATION.USERPROFILES',
        type: 'basic',
        link: '/profiles'
      },
      {
        id: 'countries',
        title: 'NAVIGATION.COUNTRIES',
        type: 'basic',
        link: '/countries'
      },
      {
        id: 'settings',
        title: 'NAVIGATION.SETTINGS',
        type: 'basic',
        link: '/settings'
      },
    ]
  },
];