import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil, filter, pluck, map, switchMap } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
// import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { TranslocoService } from '@ngneat/transloco';
import { sidebarNavigation } from 'app/core/navigation/sidebar-navigation';
import _ from 'lodash';
import { AuthService } from 'app/core/auth/auth.service';
import { sidebarCompanyNavigation } from 'app/core/navigation/sidebar-company-navigation';
@Component({
    selector: 'classic-layout',
    templateUrl: './classic.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: FuseNavigationItem[] = [];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _authService: AuthService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // // Subscribe to navigation data
        // this._navigationService.navigation$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((navigation: Navigation) => {
        //         this.navigation = navigation;
        //     });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        // recargo la traduccion si hya un cambio de lenguaje
        this._translocoService.langChanges$
            .pipe(takeUntil(this._unsubscribeAll))
            .pipe(switchMap(() => this._translocoService.selectTranslation()))
            .subscribe(translation => {
                if (!_.isEmpty(translation)) {
                    // rearmo el sidebar
                    if (this._authService.loggedIn && this._authService.currentAuth.isAdmin) {
                        // si es admin, pongo su navegacion
                        this.navigation = this._navigationService.createNavigation(sidebarNavigation, translation);
                    } else {
                        // caso contrario, es company
                        this.navigation = this._navigationService.createNavigation(sidebarCompanyNavigation, translation);
                    }
                }
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();

            /// Esto es para que el area de dibujo de un elemento recalcule el tamaño al abrir/cerrar el sidebar
            /// Lo hago asi ya que el sidebar tiene una animacion al abrir y cerrar
            /// Se repite el  evento hasta llegar a 500ms que es un tiempo mayor del que tarda la animacion del sidebar
            /// Cada 16ms ya que es el tiempo de refresco de pantalla ( 1000ms/60fps = 16.6 ms)
            for(let i = 0; i < 500; i += 16)
            setTimeout(() => {
              window.dispatchEvent(new Event('resize'));
            }, i);
        }
    }
}
