
import {MatPaginatorIntl} from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';

export class CustomPaginator extends MatPaginatorIntl  {
    translate: TranslocoService;
    itemsPerPageLabel = 'items per page';
    nextPageLabel     = 'next';
    previousPageLabel = 'prev';
    lastPageLabel = 'last Page';
    firstPageLabel = 'first Page';
    of = 'of';

    getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
            return '0 ' + this.of + ' ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' ' + this.of + ' ' + length;
    };

    injectTranslateService(translate: TranslocoService): void {
        this.translate = translate;
        const lang = this.translate.getActiveLang();
        const translations = this.translate.getTranslation(lang);

        this.translate.events$.subscribe(() => {
            const lang = this.translate.getActiveLang();
            const transl = this.translate.getTranslation(lang);
            this.translateLabels(transl);
        });

        this.translate.langChanges$.subscribe((lang) => {
          const transl = this.translate.getTranslation(lang);
          this.translateLabels(transl);
        });
        this.translateLabels(translations);

    }

    translateLabels(translations): void {
        this.itemsPerPageLabel = translations['COMMON.PAGINATOR.ITEMS_PER_PAGE'];
        this.nextPageLabel = translations['COMMON.PAGINATOR.NEXT_PAGE'];
        this.previousPageLabel = translations['COMMON.PAGINATOR.PREVIOUS_PAGE'];
        this.firstPageLabel = translations['COMMON.PAGINATOR.FIRST_PAGE'];
        this.lastPageLabel = translations['COMMON.PAGINATOR.LAST_PAGE'];
        this.of = translations['COMMON.PAGINATOR.OF'];
        this.changes.next();
    }

}
