import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { CustomCurrencyPipe } from './pipe/custom-currency.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoCoreModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoCoreModule,
        CustomCurrencyPipe
        
    ],
    declarations: [
      CustomCurrencyPipe
    ]
})
export class SharedModule
{
}
