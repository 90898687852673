import { Serializable } from "app/core/entities/serializable.model";
import { TranslatableFieldModel } from "app/shared/models/data/translatable-field.model";
import { CountryTaxModel } from "../../country-taxes/models/country-tax.model";

export class CountryModel extends Serializable {
    public id: number;
    public code: string;
    public name: TranslatableFieldModel;
    public nameLang: string;
    public taxes: CountryTaxModel[];

    newModel(data: any): CountryModel {
        const dataParsed = new CountryModel(data);

        if (data.taxes) {
            dataParsed.taxes = CountryTaxModel.createOne(data.taxes, new CountryTaxModel());
        }

        return dataParsed;
    }

    // solo me quedo con las variables que necesito para el filtro
    prepareDataToFilter(): any {
        return {
            id: this.id,
            code: this.code,
            nameLang: this.nameLang
        }
    }

    prepareForStore(): any {
        const data = {
            code: this.code,
            name: this.name
        };
        return data;
    }

    get valueAutocomplete() {
        return this.nameLang;
    }
}