import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError, map } from 'rxjs';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';
import { Auth } from 'app/shared/models/auth.model';
import { BaseService } from '../services/base.service';
import { Router } from '@angular/router';
import { GeneralResponse } from 'app/shared/models/general-response.model';
import { ResetDataModel } from 'app/modules/auth/reset-password/reset-data.model';

@Injectable()
export class AuthService extends BaseService {
    private auth: Auth;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        public _router: Router,
        private _translocoService: TranslocoService
    ) {

        super(_router);

        this._translocoService.setActiveLang('es');

        // si esta logedo leavanto el objeto auth
        if (this.loggedIn) {
            this.auth = new Auth();
            this.auth.id = localStorage.getItem('winart_id');
            this.auth.name = localStorage.getItem('winart_name');
            this.auth.email = localStorage.getItem('winart_email');
            this.auth.token = localStorage.getItem('winart_token');
            this.auth.tokenType = localStorage.getItem('winart_tokenType');
            this.auth.type = localStorage.getItem('winart_type');
            this.auth.factory = JSON.parse(localStorage.getItem('winart_factory'));
            this.auth.language = JSON.parse(localStorage.getItem('winart_language'));
            // if (localStorage.getItem('winart_company_profile')) {
            //     this.auth.userCompanyProfile = JSON.parse(localStorage.getItem('winart_company_profile'));
            // }
            if (localStorage.getItem('winart_reports')) {
                this.auth.reports = JSON.parse(localStorage.getItem('winart_reports'));
            }
            this.setLanguage();
        }
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        const url = `${this._api}login`;
        return this._httpClient.post<GeneralResponse>(url, credentials).pipe(
            map(res => {
                this.auth = new Auth(res.data);
                this.loginWriteStorage();
                return new GeneralResponse('', false, false, this.auth);
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    // signInUsingToken(): Observable<any> {
    //     // Sign in using the token
    //     return this._httpClient.post('api/auth/sign-in-with-token', {
    //         accessToken: this.accessToken
    //     }).pipe(
    //         catchError(() =>

    //             // Return false
    //             of(false)
    //         ),
    //         switchMap((response: any) => {

    //             // Replace the access token with the new one if it's available on
    //             // the response object.
    //             //
    //             // This is an added optional step for better security. Once you sign
    //             // in using the token, you should generate a new one on the server
    //             // side and attach it to the response object. Then the following
    //             // piece of code can replace the token with the refreshed one.
    //             if (response.accessToken) {
    //                 this.accessToken = response.accessToken;
    //             }

    //             // Set the authenticated flag to true
    //             this._authenticated = true;

    //             // Store the user on the user service
    //             this._userService.user = response.user;

    //             // Return true
    //             return of(true);
    //         })
    //     );
    // }

    /**
     * Sign out
     */
    signOut(): void {
        this._httpClient.post<GeneralResponse>(`${this._api}logout`, null)
            .pipe(
                map((response) => {
                    return new GeneralResponse('', false, false, response);
                }),
                catchError(err => {
                    return this.handleError(err);
                })
            )
            .subscribe(
                () => {
                    this.auth = null;
                    localStorage.clear();
                    location.reload();
                },
                () => {
                    this.auth = null;
                    localStorage.clear();
                    location.reload();
                },
            );
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this.auth?.id) {
            return of(true);
        }

        // Check the access token availability
        if (!this.getToken()) {
            return of(false);
        }

        return of(true);

        // // Check the access token expire date
        // if (AuthUtils.isTokenExpired(this.accessToken)) {
        //     return of(false);
        // }

        // // If the access token exists and it didn't expire, sign in using it
        // return this.signInUsingToken();
    }

    // metodo para enviar el mail de reseteo de contraseña
    forgotPassword(data): Observable<{} | GeneralResponse> {
        const url = `${this._api}forgot-password`;
        return this._httpClient.post<GeneralResponse>(url, data).pipe(
            map((response) => {
                return new GeneralResponse(response.message, response.error, false);
            }),
            catchError((err) => {
                return this.handleError(err);
            })
        );
    }

    // cambiar contraseña
    resetPassword(data: ResetDataModel): Observable<{} | GeneralResponse> {
        const url = `${this._api}reset-password`;
        return this._httpClient
            .post<GeneralResponse>(url, data.resetPasswordPrepareStore())
            .pipe(
                map((res) => {
                    this.auth = new Auth(res.data);
                    this.loginWriteStorage();
                    return new GeneralResponse("", false, false, this.auth);
                }),
                catchError((err) => {
                    return this.handleError(err);
                })
            );
    }

    setLanguage(): void {
        this._translocoService.setActiveLang(this.auth.language.tag);
    }

    loginWriteStorage(): void {
        localStorage.setItem('winart_name', this.auth.name);
        localStorage.setItem('winart_id', this.auth.id);
        localStorage.setItem('winart_email', this.auth.email);
        localStorage.setItem('winart_token', this.auth.token);
        localStorage.setItem('winart_tokenType', this.auth.tokenType);
        localStorage.setItem('winart_type', this.auth.type);
        localStorage.setItem('winart_factory', JSON.stringify(this.auth.factory));
        localStorage.setItem('winart_language', JSON.stringify(this.auth.language));
        // localStorage.setItem('winart_company_profile', this.auth.userCompanyProfile ? JSON.stringify(this.auth.userCompanyProfile) : '');
        localStorage.setItem('winart_reports', this.auth.reports ? JSON.stringify(this.auth.reports) : '');

        this.setLanguage();
    }

    setBrowserLang() {
        // lenguaje del navegador
        const lang = getBrowserLang();
        // traigo los lenguajes permitidos
        const availableLangs = this._translocoService.getAvailableLangs().map(l => l.id);
        // controlo si es un lenguaje permitido
        if (availableLangs.includes(lang)) {
            this._translocoService.setActiveLang(lang);
        } else {
            // si no es permitido seteo por defecto el español
            this._translocoService.setActiveLang('es');
        }
    }

    private getItem(name: string): string {
        return localStorage.getItem(name);
    }

    getToken(): string {
        const token = this.getItem('winart_token');
        return token ? `${this.getItem('winart_tokenType')} ${token}` : null;
    }


    get loggedIn(): boolean {
        return this.getToken() !== null;
    }

    get currentAuth(): Auth {
        return this.auth;
    }
}
