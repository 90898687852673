import { Serializable } from "app/core/entities/serializable.model";

export class LangModel extends Serializable{

    public id: number;
    public name: string;
    public tag: string;

    newModel(data: any): LangModel {
        return new LangModel(data);
    }
}
