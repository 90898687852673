import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomSnackBarComponent} from './custom-snack-bar.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
    declarations: [
        CustomSnackBarComponent
    ],
    imports: [
        CommonModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        SharedModule,
        MatSnackBarModule,
        MatIconModule
    ],
    entryComponents: [
        CustomSnackBarComponent,
    ]
})
export class CustomSnackBarModule {}
