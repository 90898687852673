import { Serializable } from "app/core/entities/serializable.model";
import { CountryModel } from "../../../modules/admin/countries/countries/models/country.model";
import { CurrencyModel } from "./currency.model";

export class FactoryModel extends Serializable {
    public id: number;
    public code: string;
    public businessName: string;
    public businessId: string;
    public country: CountryModel;
    public currency: CurrencyModel;

    newModel(data: any): FactoryModel {
        const dataParsed = new FactoryModel(data);

        if (data.country) {
            dataParsed.country = CountryModel.createOne(data.country, new CountryModel);
        }

        if (data.currency) {
            dataParsed.currency = CurrencyModel.createOne(data.currency, new CurrencyModel);
        }

        return dataParsed;
    }
}